import * as PIXI from 'pixi.js'

// 大炮 
export default class GameOnePao {
    constructor(fatherContaner,Objs){
        this._devicePixelRatio =  window.devicePixelRatio || 1 ;
        this._fatherContaner = fatherContaner

        // 炮 
        this.paoSprite = new PIXI.Sprite(Objs.textTexture[0]);
        let pao_w = Objs.window_w*332/803;
        let pao_h =  Objs.window_w*409/803;
        this.paoSprite.width = pao_w;
        this.paoSprite.height =pao_h;
        this.paoSprite.anchor.set(0.5,0.5)
        this.paoSprite.x = Objs.window_w - pao_w-50/ this._devicePixelRatio
        this.paoSprite.y = Objs.window_h + pao_h*0.05

        // 线
        this.lineSprite = new PIXI.Sprite(Objs.textTexture[1]);
        this.lineSprite.width = Objs.window_w*18/803;
        let lineHeight = Objs.window_w*1636/803;
        this.lineSprite.height =lineHeight
        this.lineSprite.x = 0
        this.lineSprite.y = -pao_h*1.2-lineHeight //-lineHeight - pao_h * 2
        this.lineSprite.alpha = 0;
        this.paoSprite.addChild(this.lineSprite)

        // const rectangle = new PIXI.Graphics();
        // rectangle.beginFill(0xff0000,0.8);
        // // rectangle.lineStyle(4, 0xFFff00, 0.5);//添加外边框
        // rectangle.drawRect(0,0 , 100,100);
        // rectangle.x = 0;
        // rectangle.y = -pao_h*2+100
        // rectangle.endFill();
        // this.paoSprite.addChild(rectangle)

        //  // 炮弹
        this.pdSprite = new PIXI.Sprite(Objs.textTexture[2]);
        let bulletW =  Objs.window_w*64/803;
        this.pdSprite.width =bulletW
        this.pdSprite.height =bulletW
        this.pdSprite.anchor.set(0.5,0.5)
        this.pdSprite.x = 0
        this.pdSprite.y = -pao_h * 0.6
        this.pdSprite.alpha = 0;
        this.paoSprite.addChild(this.pdSprite)
        this._fatherContaner.addChild(this.paoSprite)
    }
    pao(){
        return this.paoSprite;
    }
    line(){
        return this.lineSprite;
    }
    paodan(){
        return this.pdSprite;
    }
}