import * as PIXI from 'pixi.js'

// picture module
export default class Bullet {
    constructor(fatherContaner,texture,_size,_pos,_angles){
        this._devicePixelRatio =  window.devicePixelRatio || 1 ;
        this.fatherContaner = fatherContaner;
        this.bulletSprite = new PIXI.Sprite(texture)
        this.bulletSprite.width = _size
        this.bulletSprite.height =  _size
        this.bulletSprite.x =_pos.x;
        this.bulletSprite.y = _pos.y;
        this.bulletSprite.anchor.set(0.5,0.5)
        this.angles = _angles;
        this.speed = 10/this._devicePixelRatio
        this.isDestroy = false;
        this.minY = _size;
        this.maxW =window.innerWidth/this._devicePixelRatio
        this.fatherContaner.addChild(this.bulletSprite)
    }
    getSpreite(){
        return this.bulletSprite;
    }
    bulletTick(){
        if(this.bulletSprite.y < -this.minY || this.bulletSprite.x <=  -this.minY|| this.bulletSprite.x >=  this.maxW + this.minY){
            this.destroy();
        }else{
            this.bulletSprite.y -= this.speed*Math.cos(this.angles);
            this.bulletSprite.x -=this.speed*Math.sin(this.angles); 
        }
        
    }
    destroy(){
        this.isDestroy = true;
        this.fatherContaner.removeChild(this.bulletSprite)
    }
}