
import $ from "jquery"
import * as PIXI from 'pixi.js'
import gsap from "gsap"
import "../css/reset.less"
import "../css/index.less"

import Bump from "bump.js"
import "./gameinput1.js"
// import { angleToRadian } from "./smallpack"
import Bullet from "./gameonebullet.js"
import ABSprite from "./gameoneab.js"
import PHMei from "./gameonemei.js"
import GameOnePao from "./gameonepao.js"
import gameOneTopOne from "./gameonetopone.js"
import Man from "./gameoneman.js"


// import VConsole from "vconsole"
// new VConsole();


let bgMp3= document.getElementById('bgmusic');
let clickMp3= document.getElementById('clickmusic');
let baozhaMp3= document.getElementById('baozhamusic');
let fasheMp3= document.getElementById('fashemusic');
let timerMp3= document.getElementById('timermusic');

$(function () {

    $("body").on("click",onBodyTapFn)
    function onBodyTapFn(){
        bgMp3.muted = false;
        bgMp3.play();
        clickMp3.play();
        baozhaMp3.play();
        fasheMp3.play();
        timerMp3.play();

        clickMp3.pause();
        baozhaMp3.pause();
        fasheMp3.pause();
        timerMp3.pause();
        $("body").off("click",onBodyTapFn)
    }

    function clickMusicPlay(){
        clickMp3.currentTime = 1
        clickMp3.play();
    }
    function baozhaMusicPlay(){
        baozhaMp3.currentTime = 1
        baozhaMp3.play();
    }
    function fasheMusicPlay(){
        fasheMp3.currentTime = 1
        fasheMp3.play();
    }

    function timerMusicPlay(){
        timerMp3.currentTime = 1
        timerMp3.play();
    }

    console.log(window.innerWidth,window.innerHeight)

    let _devicePixelRatio =  window.devicePixelRatio || 1 

    let window_w = window.innerWidth;
    let window_h = window.innerHeight

    if(window_w > window_h){
        let current_w = window_w;
        window_w = window_h;
        window_h = current_w
    }
    $("body").css({width:window_w,height:window_h})

    let _w = window_w/_devicePixelRatio
    let _h = window_h/_devicePixelRatio;
    let canvas_top =0// window.innerHeight -  _h
    let ticker = PIXI.Ticker.shared;
    ticker.autoStart = false;

    let angles = 0; //大炮的角度
    let bulletPostion = null //打出炮弹的位置
    let bulletSpriteArr = []; // 装在炮弹的数组


    let gameLevel = 1;
    let isGameStart = false;  // 游戏是否开始
    let isTouch = false;     //判断是否可以touche了
    let game1HitNumber =0;   // game1  打中了几个酶
    let gameAXDistance =  _w*0.15;  //障碍物距离
    let meiArr = [];      // 酶的数组
    let meiNameIndex = 0; //添加酶的数量 和他的名字有关
    let gameTwoTimer = null;
    let gameTwoTimerIndex =11;

    let userName = ""
    let isbtnClick = false;
    

    let app = new  PIXI.Application({
        width:_w,
        height: _h,
        fps: 60,
        backgroundAlpha:0,
        antialias: true, 
        resolution: _devicePixelRatio
    });

    $(".gamecanvas").html(app.view) //把我们的canvas 放到容器的那个元素里面
    let bump = new Bump(PIXI);

    // 初始化数据
    // 大炮
    const paoContainer = new PIXI.Container();
    paoContainer.x = 0;
    paoContainer.y = 0;
    let pdTexture = PIXI.Texture.from($(".paodaoimg").attr("src"));
    let bigPao = {
        textTexture:[PIXI.Texture.from($(".paoimg").attr("src")),PIXI.Texture.from($(".lineoneimg").attr("src")),pdTexture],
        window_w:_w,
        window_h:_h,
    }
    let paoCont = new GameOnePao(paoContainer,bigPao);
    // 创建game1 top
    const gameTopCont = new PIXI.Container();
    gameTopCont.x = 0;
    gameTopCont.y = 0;
    let kuangTexture = PIXI.Texture.from($(".gameKuangImg").attr("src"));
    let onTopObj = {
        textTexture:[PIXI.Texture.from($(".gameKuangTopImg").attr("src")),kuangTexture,PIXI.Texture.from($(".gameTitleOneImg").attr("src"))],
        window_w:_w,
        window_h:_h,
    }
    // eslint-disable-next-line no-unused-vars
    let gameOneOne = new gameOneTopOne(gameTopCont,onTopObj);
    // 屏障 pingzhangimg
    let pzTexture = PIXI.Texture.from($(".pingzhangimg").attr("src"));
    let pzSprite = new PIXI.Sprite(pzTexture);
    pzSprite.width = _w*671/803;
    pzSprite.height = _w*777/803;
    pzSprite.x = _w*0.15
    pzSprite.y = _h*0.33
    // 创建a 和 b
    const abCont = new PIXI.Container();
    const ab_w = _w*137/803; //大小   objs.window_w -    objs.window_w*137/803; 
    let a_endX = _w- ab_w
    const abObjs = {
        textTexture:[PIXI.Texture.from($(".gameAImg").attr("src")),PIXI.Texture.from($(".gameBImg").attr("src")),PIXI.Texture.from($(".lineTwoimg").attr("src"))],
        window_w:_w,
        window_h:_h,
    }
    let aSprite = new ABSprite(abCont,abObjs)
    // 创建酶 
    const meiConter = new PIXI.Container()
   
    // 子弹容器
    const bulletConter = new PIXI.Container()
    bulletConter.x = 0;
    bulletConter.y = 0;

    // 左上角 任务
    let manCont = new PIXI.Container();
    manCont.x = 0;
    manCont.y = 0;


    let manObjs = {
        kuangTexture:PIXI.Texture.from($(".gameXueKuangImg").attr("src")),
        xueTexture:PIXI.Texture.from($(".gameXueImg").attr("src")),
        startTexture:PIXI.Texture.from($(".gameXueStartImg").attr("src")),
        diTexture:PIXI.Texture.from($(".gameXueDiImg").attr("src")),
        bzOneTexture:PIXI.Texture.from($(".biaozhunOneImg").attr("src")),
        bzTwoTexture:PIXI.Texture.from($(".biaozhunTwoImg").attr("src")),
        bzThreeTexture:PIXI.Texture.from($(".biaozhunThreeImg").attr("src")),
        manTexture:PIXI.BaseTexture.from($(".gameManMoveImg").attr("src")),
        mantwoTexture:PIXI.BaseTexture.from($(".gameManMove2Img").attr("src")),
        manthreeTexture:PIXI.BaseTexture.from($(".gameManMove3Img").attr("src")),
        manthree2Texture:PIXI.BaseTexture.from($(".gameManMove32Img").attr("src")),
        devicePixelRatio:_devicePixelRatio,
        window_w:_w,
        window_h:_h,
    }
    let man  = new Man(manCont,manObjs)


    

     $(".startBtns").click(function(){
        $(".gameOnePageStart").fadeOut(500);
        $(".nameDiv").fadeIn();
        $(".gameCont").fadeIn(500,()=>{
            initGameOne();
        });
        clickMusicPlay();
        
     })

     $(".inputNameBtn").click(function(){
        if(!isbtnClick){
            isbtnClick = true;
            userName = $(".usernameInput").val();
            if(userName === ""){
                isbtnClick = false;
                alert("请输入您的用户名")
            }else{
                $(".nameDiv").html("用户名："+userName)
                // $(".gameOneEndImg span").text(userName)
                $(".inputName,.inputNameBtn").fadeOut(500,function(){
                    isbtnClick = false;
                    $(".gameOnePageStart").addClass("startPages")
                });
                $(".gameLevel,.gameHelpMask,.startBtns").fadeIn();
                $(".gameOnePageStart .gameOneCent .bg").css("opacity",1)
                $(".gamestartB").css({width: "14.7%",top: "-57%",left: "15%"})
                $(".gamestartA").css({ width: "30.2%",top: "9%",left: "99%"})
                $(".gamestartA2").css({ width: "23%",top: "169%", left: "30%",animation:"none"})
                $(".gameOneCent .phmei").css({ width: "15.5%",left: "91%",top: "81%"})
            }
        }
        clickMusicPlay();
        
     })

     $(".startBtnTwo").click(function(){
        $(".gameTwoPageStart").fadeOut();
        $(".gameCont").fadeIn(500,()=>{
            initGameTwo();
        });
        clickMusicPlay();
        
     })

     



    //  initGameOne();

    function initGameOne(){

        angles = 0; //大炮的角度
        bulletPostion = null //打出炮弹的位置
        bulletSpriteArr = []; // 装在炮弹的数组
        meiArr = [];
    
    
        isGameStart = true;  // 游戏是否开始
        isTouch = true;     //判断是否可以touche了
        game1HitNumber =0;   // game1  打中了几个酶
        gameAXDistance =  _w*0.15;  //障碍物距离

        for(let k = 0;k <10;k ++){
            addPHMeiFn();
        }
        man.setManMove("dx")
        $(".gameCont .dna").css("opacity","0.1")
        
        ticker.start();
    }
    function gameOneEnd(){
        meiConter.removeChildren(0,100);
        bulletConter.removeChildren(0,100);
        gameTopCont.removeChildren(0,100);
        app.stage.removeChild(pzSprite); //删掉屏障
        // 初始化游戏2  
    
        let onTopObj = {
            textTexture:[PIXI.Texture.from($(".gameKuangTopTwoImg").attr("src")),kuangTexture,PIXI.Texture.from($(".gameTitleTwoImg").attr("src"))],
            window_w:_w,
            window_h:_h,
        }
        // eslint-disable-next-line no-unused-vars
        new gameOneTopOne(gameTopCont,onTopObj);
        paoCont.pao().rotation =0 
        aSprite.getSpreite().x = 20/_devicePixelRatio;
        aSprite.getLineMaskSpreite().x = 40/_devicePixelRatio
        man.setXueMove(0.5);

    }
    function initGameTwo(){
        $(".nameDiv").fadeIn();
        gameTwoTimerIndex =11;
        $(".gameCont").addClass("gametwo")
        gameLevel = 2;
        game1HitNumber  = 5;
        isTouch = true;
        bulletSpriteArr = [];
        isGameStart = true;
        gameAXDistance =  _w*0.15;// 游戏结束位置 a_endX
        aSprite.tickNubs = 3;
        aSprite.speed = 2/_devicePixelRatio
        for(let k = 0;k <10;k ++){
            addPHMeiFn();
        }
        gameTwoTiemrFnc();
        ticker.start();
    }

    function gameTwoEnd(){
        isGameStart = false;
        ticker.stop();
        isTouch = false;
        if(game1HitNumber < 9){
            $(".gameTwoEndFail").fadeIn(500,function(){
                $(".gameTwoEndFail").addClass("gameTwoEndErroShow")
                endClickFnc();
            });
        }else{
            $(".gameTwoEndSucces").fadeIn(500,function(){
                $(".gameTwoEndSucces").addClass("gameTwoEndSuccesShow")
                endClickFnc();
            });
        }
    }
    function endClickFnc(){
        $(".gameOneEndImg,.gameOneEndRestart,.gameOneEndImgDiv").click(function(){
            window.location.reload();
            clickMusicPlay();
         })
    }
    function gameTwoTiemrFnc(){
        gameTwoTimer = setInterval(() => {
            gameTwoTimerIndex--;
            if(gameTwoTimerIndex <= 0){
                clearInterval(gameTwoTimer);
                gameTwoEnd();
            }
            if(gameTwoTimerIndex === 6){
                timerMusicPlay();
            }
            timerNubDisplay();
        }, 1000);
    }
    function timerNubDisplay(){
        if(gameTwoTimerIndex>=10){
            $(".daojishi .nubdiv .shiwei").attr("src",$(".nubImg"+parseInt(gameTwoTimerIndex/10)).attr("src"))
            $(".daojishi .nubdiv .gewei").attr("src",$(".nubImg"+(gameTwoTimerIndex%10)).attr("src"))
        }else{
            $(".daojishi .nubdiv .shiwei").attr("src",$(".nubImg0").attr("src"))
            $(".daojishi .nubdiv .gewei").attr("src",$(".nubImg"+gameTwoTimerIndex).attr("src"))
        }
    }


    app.stage.addChild(meiConter,pzSprite,paoContainer,bulletConter,abCont,gameTopCont,manCont)

    
    // const rectangle = new PIXI.Graphics();
    // rectangle.beginFill(0xff0000,0.1);
    // rectangle.lineStyle(4, 0xFFff00, 0.5);//添加外边框
    // rectangle.drawRect(0, 0, _w*0.5, _h*0.4);
    // rectangle.x = _w*0.3;
    // rectangle.y = _h*0.38; //43
    // rectangle.endFill();
    // app.stage.addChild(rectangle)

    app.view.addEventListener("touchstart",function(e){
        if(isTouch){
            paoCont.line().alpha = 1;
            paoSpriteRotationFn(e.changedTouches[0].clientX,e.changedTouches[0].clientY-canvas_top);
            app.view.addEventListener("touchmove",ontouchMoveFunc);
            app.view.addEventListener("touchend",ontouchEndFunc);
        }
        
    })
    function ontouchMoveFunc(e){
        paoSpriteRotationFn(e.changedTouches[0].clientX,e.changedTouches[0].clientY-canvas_top);
    }
    function ontouchEndFunc(){
        paoCont.line().alpha = 0;
        app.view.removeEventListener("touchmove",ontouchMoveFunc);
        app.view.removeEventListener("touchend",ontouchEndFunc);
        addBulletFnc();
       
    }

    function paoSpriteRotationFn(touchX,touchY){
        let disx =touchX/_devicePixelRatio - paoCont.pao().x;
        let disy =touchY/_devicePixelRatio - paoCont.pao().y+ paoCont.pao().height*0.25;
        let ang = Math.atan(disx/disy)
        ang = ang < -0.8 ? -0.8 : ang > 0.8 ? 0.8 : ang;
        angles = ang
        bulletPostion = paoCont.pao().toGlobal(paoCont.paodan().position)
        paoCont.pao().rotation =-ang 
    }
    // 添加炮弹
    function addBulletFnc(){
        bulletSpriteArr.push(new Bullet(bulletConter,pdTexture,paoCont.paodan().width,bulletPostion,angles))
        fasheMusicPlay();
    }
    // 添加PH酶  
    function addPHMeiFn(){
        meiNameIndex++;
        meiArr.push(new PHMei(meiConter,PIXI.Texture.from($(".gameMeiImg").attr("src")),_w*90/803,meiNameIndex,PIXI.BaseTexture.from($(".gameBaoZhaImg").attr("src"))))
    }
    

    ticker.add(()=>{
        if(isGameStart){
            if(bulletSpriteArr.length > 0){
                for(let i = 0 ;i < bulletSpriteArr.length;i ++){
                    if(bulletSpriteArr[i] != ""){
                        if(!bulletSpriteArr[i].isDestroy){
                            bulletSpriteArr[i].bulletTick();
                            bump.hit(bulletSpriteArr[i].getSpreite(),meiConter.children,false,false,false, function(collision, platform){
                                gameHitHPMei(i, platform)
                            });
                        }
                        else{
                            bulletSpriteArr[i] = "";
                        }
                    }
                } 
                for(let j = bulletSpriteArr.length -1;j >= 0 ;j --){
                    if(bulletSpriteArr[j] == ''){
                        bulletSpriteArr.splice(j,1);
                    }
                }
    
            }  
            if(meiArr.length > 0){
                for(let i = 0;i < meiArr.length;i ++){
                    if(meiArr[i] != ""){
                        if(!meiArr[i].isDestroy){
                            meiArr[i].Tick();
                        }
                    }
                }
                for(let j = meiArr.length -1;j >= 0 ;j --){
                    if(meiArr[j] == ''){
                        meiArr.splice(j,1);
                        addPHMeiFn();
                    }
                }
            }
            aSprite.Tick(gameAXDistance,()=>{
                // 在这里，我们数据清楚  a 已经移动到B了
                if(gameLevel === 1 && isGameStart){
                    isGameStart = false;
                    ticker.stop();
                    isTouch = false;
                    man.setManBiaoZhun(2)
                    setTimeout(() => {
                        $(".nameDiv").fadeOut();
                        $(".gameTwoPageStart").fadeIn(500,function(){
                            $(".gameCont").fadeOut();
                            gameOneEnd();
                        })
                    }, 1000);
                }
                
               
                
            });
        }
       
        
    });
    function gameHitHPMei(i, platform){
        let hitNameStr = platform.name;
        let isEffectiveHit = false;//是否是有效的碰撞
        for(let k = 0;k < meiArr.length;k ++){
            if(meiArr[k] != ""){
                if(!meiArr[k].isDestroy){
                    if(hitNameStr !== null && meiArr[k].getSpreite().name ===  hitNameStr  && meiArr[k].getSpreite().alpha === 1){
                        meiArr[k].destroy();
                        meiArr[k]=""
                        isEffectiveHit = true;
                        break;
                    }
                }
                
            }
        }
        if(!isEffectiveHit) return;

        
        if(bulletSpriteArr[i] !== ""){
            bulletSpriteArr[i].destroy();
            bulletSpriteArr[i] = ""
        }
        
        if(gameLevel === 1){
            let objs = {
                zhangaiX:_w*(0.15+game1HitNumber*0.05),
                alpha:1-(game1HitNumber*0.2)
            }
            game1HitNumber++;
            let currentAlpha = 1-(game1HitNumber*0.2);
            if(currentAlpha<=0){
                currentAlpha = 0;
            }
            gsap.to(objs,{
                zhangaiX:_w*(0.15+game1HitNumber*0.05),
                alpha:currentAlpha,
                duration:0.3,
                ease:"line",
                onUpdate:()=>{
                    pzSprite.x = objs.zhangaiX;
                    pzSprite.alpha = objs.alpha;
    
                }
            })
            // 打酶 的数量
            if(game1HitNumber < 7){//5---7
                gameAXDistance = _w*(0.15+game1HitNumber*0.05*5/7) //(100+game1HitNumber*50)/_devicePixelRatio
                man.setXueMove(game1HitNumber*0.1*5/7)
                $(".gameCont .dna").css("opacity",game1HitNumber*0.1*5/7)
            }else{
                aSprite.tickNubs = 1;
                aSprite.speed = 2/_devicePixelRatio;
                gameAXDistance = a_endX
                man.setXueMove(0.5)
            }
            if(game1HitNumber === 5){
                man.setManMove("zx")
            }
            
        }else if(gameLevel === 2){

            game1HitNumber++;
            if(game1HitNumber <= 8){
                gameAXDistance = _w*(0.15+(game1HitNumber-5)*0.05) //(100+game1HitNumber*50)/_devicePixelRatio
                $(".gameCont .dna").css("opacity",game1HitNumber*0.1)
            }else if(game1HitNumber === 9){
                let a_currentX = aSprite.getSpreite().x;
                let _speed = (a_endX-a_currentX)/(gameTwoTimerIndex+0.5); //每一秒走的距离
                gameAXDistance = a_endX
                aSprite.tickNubs = 1;
                aSprite.speed = _speed/60;
                man.setManBiaoZhun(3)
            }
            if(game1HitNumber === 8){
                man.setManMove("happy")
            }

            if(game1HitNumber <= 10){
                man.setXueMove(game1HitNumber*0.1)
            }
        }
        baozhaMusicPlay();
       
        //
        // henLineMask.x =  gameAXDistance-20/_devicePixelRatio
    }

 



});