export function myRand(min, max){
    return Math.floor(Math.random() * (max - min +1) + min);
}

// 角度转弧度
export function angleToRadian(_angle){
    return 3.14/180*_angle
}
// 弧度转角度
export function radianToAngle(_radian){
    return 180/3.14*_radian
}