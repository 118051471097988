import * as PIXI from 'pixi.js'

// 游戏1第一关中的顶部部分 
export default class gameOneTopOne {
    constructor(fatherContaner,Objs){
        this._devicePixelRatio =  window.devicePixelRatio || 1 ;
        this._fatherContaner = fatherContaner
        // top
        this.topSprite = new PIXI.Sprite(Objs.textTexture[0]);
        let top_h =  Objs.window_h*0.37535;
        this.topSprite.width = Objs.window_w;
        this.topSprite.height =top_h
        this.topSprite.x = 0
        this.topSprite.y =0
        this._fatherContaner.addChild(this.topSprite)
        // 框
        this.kuangSprite = new PIXI.Sprite(Objs.textTexture[1]);
        let kuangH = Objs.window_h*0.7486;
        this.kuangSprite.width = Objs.window_w;
        this.kuangSprite.height =kuangH;
        this.kuangSprite.x = 0
        this.kuangSprite.y = Objs.window_h*(1-0.7486);
        this._fatherContaner.addChild(this.kuangSprite)
        // title
        this.titleSprite = new PIXI.Sprite(Objs.textTexture[2]);
        this.titleSprite.anchor.set(0.5,0.5)
        this.titleSprite.width = Objs.window_w*0.178;
        this.titleSprite.height =Objs.window_h*0.033;
        this.titleSprite.x = Objs.window_w/2
        this.titleSprite.y =kuangH*0.385
        this._fatherContaner.addChild(this.titleSprite)


    }
}