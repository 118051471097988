import * as PIXI from 'pixi.js'
import gsap from "gsap"

// picture module
export default class Man {
    constructor(fatherContaner,objs){
        this.fatherContaner = fatherContaner;

        // 框 
        this.kuangSprite = new PIXI.Sprite(objs.kuangTexture);
        let kuangHeight = objs.window_w*179/803;
        let kuangWidth = objs.window_w*160/803;
        let manWidth = kuangWidth *0.9;
        let kuangX =objs.window_w*0.08;
        let kuangY = objs.window_h*0.067;
        this.kuangSprite.width = kuangWidth;
        this.kuangSprite.height = kuangHeight;
        this.kuangSprite.x =kuangX;
        this.kuangSprite.y =kuangY;

        // 动画组
        let animationHeight = manWidth*329/287;
        this.manContainer = new PIXI.Container();
        this.manContainer.x = 0;
        this.manContainer.y = 0;

        // 蹲
        let dengXiaTexture = [];
        for(let i = 0;i < 10;i ++){
            dengXiaTexture.push(new PIXI.Texture(objs.manTexture,new PIXI.Rectangle(i*287,0,287,329)))
        }
        this.dengxiaAnimation = new PIXI.AnimatedSprite(dengXiaTexture);
        
        // 直行
        let zhiXingTexture =  []
        for(let j = 0;j < 8;j ++){
            zhiXingTexture.push(new PIXI.Texture(objs.mantwoTexture,new PIXI.Rectangle(j*287,0,287,329)))
        }
        this.zhiXingAnimation = new PIXI.AnimatedSprite(zhiXingTexture);
        // // happy
        let happyTexture = []
        for(let k = 0;k < 10;k ++){
            happyTexture.push(new PIXI.Texture(objs.manthreeTexture,new PIXI.Rectangle(k*287,0,287,329)))
        }
        for(let l = 0;l < 5;l ++){
            happyTexture.push(new PIXI.Texture(objs.manthree2Texture,new PIXI.Rectangle(l*287,0,287,329)))
        }
        this.happyAnimation = new PIXI.AnimatedSprite(happyTexture);


        this.dengxiaAnimation.width =  this.zhiXingAnimation.width = this.happyAnimation.width = manWidth
        this.dengxiaAnimation.height = this.zhiXingAnimation.height = this.happyAnimation.height  = animationHeight;
        this.dengxiaAnimation.x =  this.zhiXingAnimation.x = this.happyAnimation.x = kuangX+manWidth*0.05;
        this.dengxiaAnimation.y =  this.zhiXingAnimation.y = this.happyAnimation.y = kuangY-(animationHeight*1.1-kuangHeight);
        this.dengxiaAnimation.animationSpeed = this.zhiXingAnimation.animationSpeed = this.happyAnimation.animationSpeed = 0.09;

        // 底 
        this.diSprite = new PIXI.Sprite(objs.diTexture);
        let diWidth = objs.window_w*184/803;
        let diHeight = objs.window_w*46/803;
        let diX = objs.window_w*0.065;
        let diY = kuangY+kuangHeight-diHeight/2;
        this.diSprite.width = diWidth;
        this.diSprite.height = diHeight;
        this.diSprite.x = diX
        this.diSprite.y = diY;

        // 血 
        this.xueMaxWidht = objs.window_w*0.15;
        this.xueStartX = -objs.window_w*0.07;
        this.xueSprite = new PIXI.Sprite(objs.xueTexture);
        this.xueSprite.width = objs.window_w*161/803;
        this.xueSprite.height = objs.window_w*25/803;
        // this.xueSprite.x = *1+this.xueStartX;
        this.xueSprite.x = this.xueStartX;
        this.xueSprite.y = kuangY+kuangHeight-diHeight*1/3;

        this.xueMask = new PIXI.Graphics();
        this.xueMask.beginFill(0xffff00,1);
        this.xueMask.drawRect(0, 0, diWidth, diHeight);
        this.xueMask.endFill();
        this.xueMask.x =diX;
        this.xueMask.y =diY;
    
        this.xueSprite.mask = this.xueMask


        // 心 
        this.startSprite = new PIXI.Sprite(objs.startTexture);
        this.startSprite.width = objs.window_w*44/803;
        this.startSprite.height = objs.window_w*42/803;
        this.startSprite.x = objs.window_w*0.05;
        this.startSprite.y =  kuangY+kuangHeight-diHeight*2/3;


        this.biaozhunCont = new PIXI.Container();
        this.zbOne = new PIXI.Sprite(objs.bzOneTexture);
        this.zbTwo = new PIXI.Sprite(objs.bzTwoTexture);
        this.zbThree = new PIXI.Sprite(objs.bzThreeTexture);

        this.zbOne.width = this.zbTwo.width = this.zbThree.width = objs.window_w*170/803;
        this.zbOne.height = this.zbTwo.height = this.zbThree.height = objs.window_w*48/803;
        this.zbOne.x = this.zbTwo.x = this.zbThree.x = objs.window_w*0.075;
        this.zbOne.y = this.zbTwo.y =  this.zbThree.y =   kuangY+kuangHeight*1.15//-diHeight*2/3;

        // this.dengxiaAnimation  this.zhiXingAnimation  this.happyAnimation

        // this.dengxiaAnimation.play();
        this.biaozhunCont.addChild(this.zbOne)
        this.fatherContaner.addChild(this.manContainer,this.kuangSprite,this.diSprite,this.xueSprite,this.startSprite,this.xueMask,this.biaozhunCont)
    }
    getXueSpreite(){
        return this.xueSprite;
    }
    setManMove(manStr){
        this.manContainer.removeChildren(0,10);
        if(manStr === "dx"){
            this.manContainer.addChild(this.dengxiaAnimation);
            this.dengxiaAnimation.animationSpeed = 0.06;
            this.dengxiaAnimation.play();
        }else if(manStr === "zx"){
            this.manContainer.addChild(this.zhiXingAnimation);
            this.zhiXingAnimation.animationSpeed = 0.08;
            this.zhiXingAnimation.play();
        }else if(manStr === "happy"){
            this.manContainer.addChild(this.happyAnimation);
            this.happyAnimation.animationSpeed = 0.1;
            this.happyAnimation.play();
        }

    }
    setManBiaoZhun(nubs){
        this.biaozhunCont.removeChildren(0,10);
        if(nubs === 2){
            this.biaozhunCont.addChild(this.zbTwo);
        }else if(nubs === 3){
            this.biaozhunCont.addChild(this.zbThree);
        }else{
            this.biaozhunCont.addChild(this.zbOne);

        }
    }
    setXueMove(moveBili) {
        let currentX = this.xueMaxWidht*moveBili+this.xueStartX;
        let objs = {
            xueX:this.xueSprite.x
        }
        gsap.to(objs,{
            xueX:currentX,
            duration:1,
            ease:"line",
            onUpdate:()=>{
                this.xueSprite.x = objs.xueX;
            }
        })

    }
}