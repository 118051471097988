import * as PIXI from 'pixi.js'
import {myRand,angleToRadian} from "./smallpack.js"
import gsap from "gsap"

// picture module
export default class PHMei {
    constructor(fatherContaner,texture,_size,name_index,baozhaBase){
        this._devicePixelRatio =  window.devicePixelRatio || 1 ;
        this._w =window.innerWidth/this._devicePixelRatio
        this._h = window.innerHeight/this._devicePixelRatio
        
        this.fatherContaner = fatherContaner;
        this.maxW =window.innerWidth/this._devicePixelRatio;
        this.meiSprite = new PIXI.Sprite(texture)
        this.meiSprite.name="ph_name_"+name_index
        let scale_size =_size* myRand(8,10)/10
        this.meiSprite.width = 0
        this.meiSprite.height =  0
        this.meiSprite.x = myRand(this._w*0.3,this._w*0.8);
        this.meiSprite.y = myRand(this._h*0.38,this._h*0.61);
        this.meiSprite.anchor.set(0.5,0.5)
        this.meiSprite.alpha =0;
        this.angles = angleToRadian(myRand(0,360));
        this.meiSprite.rotation = this.angles;
        this.speed = 0.2/this._devicePixelRatio
        this.isDestroy = false;
        this.indexof = 0;
        this.isMove = true;

        let runTexture = [];
        runTexture.push(new PIXI.Texture(baozhaBase,new PIXI.Rectangle(0,0,260,174)))
        runTexture.push(new PIXI.Texture(baozhaBase,new PIXI.Rectangle(260,0,260,174)))
        runTexture.push(new PIXI.Texture(baozhaBase,new PIXI.Rectangle(520,0,260,174)))
        this.runAnimation = new PIXI.AnimatedSprite(runTexture);
        this.runAnimation.x = 10000;
        this.runAnimation.anchor.set(0.5,0.6)
        this.runAnimation.scale.set(scale_size*2/this.runAnimation.width)
        this.runAnimation.alpha = 0;
        this.runAnimation.animationSpeed  = 0.09;
        this.runAnimation.loop = false


        // const rectangle = new PIXI.Graphics();
        // rectangle.beginFill(0xff0000,0.1);
        // rectangle.lineStyle(4, 0xFFff00, 0.5);//添加外边框
        // rectangle.drawRect(0,0 , this._w*0.5,this._h*0.23);
        // rectangle.x = this._w*0.3;
        // rectangle.y = this._h*0.38; //43
        // rectangle.endFill();
        // this.fatherContaner.addChild(rectangle)

        this.fatherContaner.addChild(this.meiSprite,this.runAnimation)
        this.runAnimation.onComplete = ()=>{
            let objs = {
                alpha:1
            }
            gsap.to(objs,{
                alpha:0,
                duration:0.3,
                ease:"line",
                onUpdate:()=>{
                    this.runAnimation.alpha = objs.alpha
                },
                onComplete:()=>{
                    this.fatherContaner.removeChild(this.runAnimation)
                }
            })
        }
        this.showMeiFn(scale_size);
    }
    showMeiFn(scale_size){
        let objs = {
            alpha:0,
            scaleNub:0
        }
        gsap.to(objs,{
            alpha:1,
            scaleNub:scale_size,
            duration:0.5,
            ease:"line",
            onUpdate:()=>{
                this.meiSprite.alpha = objs.alpha
                this.meiSprite.width =  objs.scaleNub
                this.meiSprite.height =   objs.scaleNub
            }
        })
    }

    getSpreite(){
        return this.meiSprite;
    }
    Tick(){
        if(!this.isDestroy){
            if(this.indexof >= 200){
                this.indexof = 0;
                this.isMove = false;
                let objs = {
                    nubs:this.angles
                }
                this.angles =angleToRadian(myRand(0,360));
                gsap.to(objs,{
                    nubs:this.angles,
                    duration:0.5,
                    ease:"line",
                    onUpdate:()=>{
                        this.meiSprite.rotation = objs.nubs
                    },
                    onComplete:()=>{
                        this.isMove = true;
                    }
                })
            }
            if(this.isMove){
                this.indexof ++;
                this.meiSprite.y -= this.speed*Math.cos(this.angles);
                this.meiSprite.x +=this.speed*Math.sin(this.angles); 
            }
        }
        
       
    }
    destroy(){
        this.isDestroy = true;
        this.meiSprite.alpha  = 0;

        this.runAnimation.x = this.meiSprite.x;
        this.runAnimation.y = this.meiSprite.y;
        this.runAnimation.alpha = 1;
        this.runAnimation.play();

        this.fatherContaner.removeChild(this.meiSprite)
    }
}