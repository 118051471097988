import * as PIXI from 'pixi.js'

// picture module
export default class ABSprite {
    constructor(fatherContaner,objs){
        this._devicePixelRatio =  window.devicePixelRatio || 1 ;
        this.fatherContaner = fatherContaner;

        this.abSprite = new PIXI.Sprite(objs.textTexture[0])
        let _size = objs.window_w*137/803; //大小
        this.abSprite.width = _size
        this.abSprite.height =  _size
        this.abSprite.x =20/this._devicePixelRatio;
        this.abSprite.y = objs.window_h*0.58

        this.bSprite = new PIXI.Sprite(objs.textTexture[1])
        this.bSprite.width = _size
        this.bSprite.height =  _size
        this.bSprite.x = objs.window_w - _size
        this.bSprite.y = objs.window_h*0.58



        this.henLineSprite = new PIXI.Sprite(objs.textTexture[2])
        this.henLineSprite.width = objs.window_w*633/803
        this.henLineSprite.height =  objs.window_w*2/803

        this.henLineSprite.x = 40/this._devicePixelRatio;
        this.henLineSprite.y =objs.window_h*0.62
        this.henLineMask = new PIXI.Graphics();
        this.henLineMask.beginFill(0xff0000,0.1);
        this.henLineMask.drawRect(0, 0, this.henLineSprite.width, objs.window_h*0.02);
        this.henLineMask.endFill();
        this.henLineMask.x = 40/this._devicePixelRatio;
        this.henLineMask.y =objs.window_h*0.61
    
        this.henLineSprite.mask = this.henLineMask



        this.speed = 5/this._devicePixelRatio
        this.size = _size;
        this.indexof = 0;
        this.tickNubs = 4;//多少帧执行一次(让速度不会太快)， 且等于1的时候，每帧都会执行，
        this.maxW =window.innerWidth/this._devicePixelRatio
        this.fatherContaner.addChild(this.henLineSprite,this.henLineMask,this.bSprite,this.abSprite)
    }
    getSpreite(){
        return this.abSprite;
    }
    getLineMaskSpreite(){
        return this.henLineMask;
    }
    Tick(maxnubs,callBackFn){
        if(this.tickNubs !== 1 &&   this.tickNubs !== 3){
            this.indexof++;
            if(this.indexof >= this.tickNubs){
             this.indexof = 0;
             if(this.abSprite.x+this.size/2 > maxnubs){
                 this.abSprite.x -= this.speed+5/this._devicePixelRatio;
             }
             this.abSprite.x += this.speed;
             this.henLineMask.x = this.abSprite.x+20/this._devicePixelRatio;
            }
        }else if(this.tickNubs === 3){
            if(this.abSprite.x+this.size/2 > maxnubs){
                return;
            }
            this.abSprite.x += this.speed;
            this.henLineMask.x = this.abSprite.x+20/this._devicePixelRatio;
        }else{
            if(this.abSprite.x+this.size*2/3 >= maxnubs){
                // 结束
                callBackFn()
            }else{
                this.abSprite.x += this.speed;
                this.henLineMask.x = this.abSprite.x+20/this._devicePixelRatio;
            }
        }
       
        
        
    }
    destroy(){
        this.fatherContaner.removeChild(this.bulletSprite)
    }
}